<template>
  <vx-card title="Customer Deposit">
          <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Deposit Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
          <div class="mt-4 flex justify-between">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="depositDate"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ globalDateFormat(picker.startDate) }} -
                {{ globalDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="
                () => {
                  this.depositDate = {startDate: null,endDate: null}
                }
              "
            ></vs-button>
            <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
          </div>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Collection Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
          <div class="mt-4 flex justify-between">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="collectDate"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ globalDateFormat(picker.startDate) }} -
                {{ globalDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="
                () => {
                  this.collectDate = {startDate: null,endDate: null}
                }
              "
            ></vs-button>
            <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
          </div>
        </div>
      </div>
    <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs :color="colorx">
          <!-- <vs-tab @click="colorx = 'danger'" label="Proposal Approved">
            <div class="con-tab-ejemplo">
              <proposal />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'danger'"  label="Draft">
            <div class="con-tab-ejemplo">
              <open ref="open" :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'warning'" label="Execution">
            <div class="con-tab-ejemplo">
              <execution :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'success'" label="Mapping">
            <div class="con-tab-ejemplo">
              <mapping :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'primary'" label="Waiting Reconcile">
            <div class="con-tab-ejemplo">
              <waiting  :collectionDate="collectDate" :depositDate="depositDate"/>
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'primary'" label="Reconcile">
            <div class="con-tab-ejemplo">
              <recon :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Failed">
            <div class="con-tab-ejemplo">
              <failed :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'primary'" label="Canceled">
            <div class="con-tab-ejemplo">
              <cancel :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'"  label="Reverse">
            <div class="con-tab-ejemplo">
              <reverse :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="By Customer">
            <div class="con-tab-ejemplo">
              <by-customer :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose"/>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import open from "./open/open.vue";
import byCustomer from "./show_by_customer/index.vue";
import execution from "./execution/execution.vue";
import cancel from "./cancel/cancel.vue"
import mapping from "./mapping/mapping.vue"
import waiting from "./waiting_recon/waiting.vue"
import recon from "./recon/recon.vue"
import failed from "./failed/failed.vue"
import create from "./form-create.vue";
import reverse from "./reverse/reverse.vue";

export default {
  components: {
    open,
    create,
    execution,
    cancel,
    mapping,
    recon,
    waiting,
    failed,
    byCustomer,
    reverse,
    DateRangePicker
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate:{},
      depositDate: {
        startDate: null,
        endDate: null,
      },
      collectDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
        this.$refs.open.closeDetail()
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>