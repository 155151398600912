var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.headerDetailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"},[_c('th',{attrs:{"width":"7%"}},[_vm._v(" Action ")])]),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.cashBank),function(tr,indextr){return _c('vs-tr',{key:indextr,class:tr.class},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('vx-tooltip',{staticClass:"mr-4",attrs:{"text":"Show"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShow(tr)}}})],1)],1)]],2),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.PersonCode)+" Name : "+_vm._s(tr.PersonName)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.DepositValue))),_c('br')])],1)}),1)],2),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"primary","buttons-hidden":false,"active":_vm.approvalPrompt},on:{"accept":_vm.approve,"cancel":_vm.closeDetail,"close":_vm.closeDetail,"update:active":function($event){_vm.approvalPrompt=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Cash Deposit "),_c('br'),_vm._v(" Are you sure to release draft "),_c('b',[_vm._v(_vm._s(this.selectedData.Code))]),_vm._v(" ? "),_c('br'),_c('br'),_c('vs-textarea',{model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)])],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' ' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}}),(_vm.renderComponent)?_c('waiting-div',{attrs:{"selected":_vm.selectedData},on:{"close":_vm.handleClose}}):_vm._e()],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }